import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)


// ElementPlus UI组件
import 'element-plus/dist/index.css'
import '@/assets/element/index.scss'
import '@/assets/css/global.css'
import '@/assets/css/common.scss'
import ElementPlus from "element-plus";
import En from "element-plus/es/locale/lang/en";

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store).use(router).use(ElementPlus, {locale: En}).mount('#app')
