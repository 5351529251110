import { createStore } from 'vuex'

export default createStore({
  state: {
    orderTimes: 0,
    orderId: 0,
    orderNo: '',
    tbcTotal: 0,
    uploadTimes: 0,
  },
  getters: {
  },
  mutations: {
    addUploadTimes(state){
      state.uploadTimes++;
    },
    changeTimes(state){
      state.orderTimes++;
    },
    changeOrderId(state, id){
      state.orderId = id;
    },
    changeOrderNo(state, no){
      state.orderNo = no;
    },
    changeTbcTotal(state, val){
      state.tbcTotal = val;
    },
    
  },
  actions: {
  },
  modules: {
  }
})
